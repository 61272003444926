$(document).on('turbolinks:load', function() {

  // Show photo options if news type is selected as photos
  // Check select list on on first load to show photos

  // console.log('************* News form js loaded')
  const el = document.getElementById("news-type-select");

  if (el) {
    const value = el.options[el.selectedIndex].value;
    if (value === 'photo_reel') {
        $('#photos-container').show();
    };
  }

  // Check on change of select list
  $("#news-type-select").on("change", function(val) {
    const el = document.getElementById("news-type-select");
    const value = el.options[el.selectedIndex].value;
    if (value === 'photo_reel') {
      if ($('#photos-container').is(':hidden')) {
        $('#photos-container').show();
      }
    } else {
      if ($('#photos-container').is(':visible')) {
        $('#photos-container').hide();
      }
    }
  });

});
