import 'bootstrap';
import './src/application.scss' // modify bootstrap here

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require('../application/disable_scroll')
require('../application/jquery_nested_form')
require('../application/payment')
require('../application/purchase_options')
require('../application/time')
require('../application/news_form')

require('../application/client_index')
require('../application/client_dimming')
require('../application/client_page_functions')

require('../application/organisation_client_index')
require('../application/organisation_client_dimming')
require('../application/organisation_client_page_functions')

// console.log("Test for jQuery ('function' means it is loaded, 'undefined' not) ", typeof jQuery);
