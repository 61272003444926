//stripe payment js

$(document).on('turbolinks:load', function() {

  var checkoutButton = document.getElementById('checkout-button');
  if (checkoutButton) {
    checkoutButton.addEventListener('click', function() {
      var purchase_option = document.querySelector('input[name="purchase_option"]:checked').value
      var large_tablets = document.getElementById('large_tablets').value;
      var small_tablets = document.getElementById('small_tablets').value;
      var stands = document.getElementById('stands').value;

      console.log('large_tablets', large_tablets)
      console.log('small_tablets', small_tablets)
      console.log('stands', stands)

      if (purchase_option == 'tablet' && large_tablets == '0' && small_tablets == '0') {
        document.getElementById('warning').classList.remove("d-none");;
        return;
      };

      fetch('/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': AUTH_TOKEN,
        },
        body: JSON.stringify({
          purchase_option: purchase_option,
          large_tablets: large_tablets,
          small_tablets: small_tablets,
          stands: stands
        })
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(response) {
          stripe = Stripe(response.publishableKey);
          return stripe.redirectToCheckout({ sessionId: response.sessionId });
        })
        .then(function(result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          if (result.error) {
            alert(result.error.message);
          }
        })
        .catch(function(error) {
          console.error('Error:', error);
        });
    });
  }

  var updateCardButton = document.getElementById('update-card-button');
  if (updateCardButton) {
    updateCardButton.addEventListener('click', function() {
      fetch('/create-update-card-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': AUTH_TOKEN,
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(response) {
          stripe = Stripe(response.publishableKey);
          return stripe.redirectToCheckout({ sessionId: response.sessionId });
        })
        .then(function(result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          if (result.error) {
            alert(result.error.message);
          }
        })
        .catch(function(error) {
          console.error('Error:', error);
        });
    });
  }

})
