import { disableScroll} from "./disable_scroll";
import { dimAtNight } from './client_dimming';
import { showNoNewsPage, changePage, logMessage } from "./client_page_functions";

// pagesArray is an array of page IDs to step through with names and times
let ipadDisplayController = (function() {

  let pagesArray = gon.pages_array;
  let arrayPosition = 0; // start with first page
  let pageTime = 0;
  let timer = 0;
  const numberOfPages = pagesArray.length;
  let dimmed = false;
  let oldPageId = ""
  let firstPass = true;

  if (gon.is_family_client) {
    console.log('Pages array:', gon.pages_array);
  }

  return function (forceForward, forceBack) { // a closure

    if (gon.is_family_client) { // if a client then dim at night
      if (dimAtNight(dimmed)) { return; }
    }

    if (numberOfPages === 0) {
      showNoNewsPage();
      return;
    }

    if (firstPass) {
      pageTime = pagesArray[0]["time"];
      $(`#${pagesArray[0]["id"]}`).fadeIn(500); // unhide first page
      logMessage(pagesArray, arrayPosition);
      firstPass = false;
    }

    if (forceForward) {
      if (numberOfPages === 1) { return }; // cannot go forward
      timer = 0;
      oldPageId = pagesArray[arrayPosition]["id"];
      arrayPosition = arrayPosition + 1;
      if (arrayPosition === numberOfPages) { arrayPosition = 0 };
      pageTime = pagesArray[arrayPosition]["time"];
      changePage(oldPageId, pagesArray[arrayPosition]["id"])
      logMessage(pagesArray, arrayPosition);
    }

    if (forceBack) {
      if (numberOfPages === 1) { return }; // cannot go back
      timer = 0;
      oldPageId = pagesArray[arrayPosition]["id"];
      arrayPosition = arrayPosition - 1;
      if (arrayPosition === -1 ) { arrayPosition = numberOfPages - 1 };
      pageTime = pagesArray[arrayPosition]["time"];
      changePage(oldPageId, pagesArray[arrayPosition]["id"])
      logMessage(pagesArray, arrayPosition);
    }

    // update the 1s timer
    if (timer < pageTime) {
      timer += 1;  // timers work in s here for ease of reading
      return;
    }

    // Timer expired - time to switch
    timer = 1;
    oldPageId = pagesArray[arrayPosition]["id"];
    arrayPosition = arrayPosition + 1;
    if (arrayPosition === numberOfPages) { arrayPosition = 0 };
    pageTime = pagesArray[arrayPosition]["time"];
    changePage(oldPageId, pagesArray[arrayPosition]["id"])
    logMessage(pagesArray, arrayPosition);
  }

}) ();


$(document).ready(function() { // don't use turbolinks on client page

  if ($('#ipad-display-container').length > 0) {
    console.log('Family Display loaded');

    disableScroll();

    ipadDisplayController(); // setup the closure

    setInterval( function() { // infinite loop to update display each s
      ipadDisplayController();
    }, 1000)

    document.getElementById('fast-forward').addEventListener('click', function(event) {
      ipadDisplayController(true, false);
    });

    document.getElementById('rewind').addEventListener('click', function(event) {
      ipadDisplayController(false, true);
    });

    if (gon.is_family_client) { // checkonline every 30m (NOT for demo)
      setInterval(function() {
        $.ajax({
          type: 'get',
          dataType: 'json',
          url: '/checkonline' // check online before attempting refresh
        }).done(function (xhr, textStatus) {
          console.log('Triggering page reload');
          window.location.reload(true);
        }).fail(function (xhr, textStatus) {
          console.error('Error offline with error:', textStatus);
          console.error(textStatus);
        })
      }, 600000); // TIMER for check online and refresh, 10 mins
    }
  }

})
