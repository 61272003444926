function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

function updateClock() {

  let now = new Date(); // current date
  let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  let date = [now.getDate(), months[now.getMonth()], now.getFullYear()].join(' ');
  let day = days[now.getDay()];

  let h =  now.getHours(), m = now.getMinutes();
  let suffix = ((h > 11) && (h < 24)) ? '<span class="time-suffix">pm</span>' : '<span class="time-suffix">am</span>';
  let time = (h > 12) ? (h-12 + ':' + addZero(m) + suffix) : (h + ':' + addZero(m) + suffix);

  let els = document.querySelectorAll('span.time');
  for (let index = 0; index < els.length; ++index) {
    els[index].innerHTML = time;
  }

  els = document.querySelectorAll('span.day');
  for (let index = 0; index < els.length; ++index) {
    els[index].innerHTML = day;
  }

  els = document.querySelectorAll('span.date');
  for (let index = 0; index < els.length; ++index) {
    els[index].innerHTML = date;
  }

}

$(document).ready(function() { // don't use turbolinks on client page
  updateClock();
  setInterval(function () { // infinite loop to update display each s
    updateClock();
  }, 1000);
})
