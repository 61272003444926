export function displayButtons() {
  // console.log('***************** in displayButtons');
  // console.log(isButtonVisible(1));
  if (isButtonVisible(1)) {
    showButton(1);
  } else {
    hideButton(1);
  };
  if (isButtonVisible(2)) {
    showButton(2);
  } else {
    hideButton(2);
  };
  if (isButtonVisible(3)) {
    showButton(3);
  } else {
    hideButton(3);
  };
  if (isButtonVisible(4)) {
    showButton(4);
  } else {
    hideButton(4);
  };
  if (isButtonVisible(5)) {
    showButton(5);
  } else {
    hideButton(5);
  };
  if (isButtonVisible(6)) {
    showButton(6);
  } else {
    hideButton(6);
  };
  if (isButtonVisible(7)) {
    showButton(7);
  } else {
    hideButton(7);
  };
  if (isButtonVisible(8)) {
    showButton(8);
  } else {
    hideButton(8);
  };
}

export function displayButtonMessages() {
  const isButton1MessageVisible = isButtonMessageVisible(1);
  const isButton2MessageVisible = isButtonMessageVisible(2);
  const isButton3MessageVisible = isButtonMessageVisible(3);
  const isButton4MessageVisible = isButtonMessageVisible(4);
  const isButton5MessageVisible = isButtonMessageVisible(5);
  const isButton6MessageVisible = isButtonMessageVisible(6);
  const isButton7MessageVisible = isButtonMessageVisible(7);
  const isButton8MessageVisible = isButtonMessageVisible(8);
  if (isButton1MessageVisible) {
    showButtonMessage(1);
  } else {
    hideButtonMessage(1);
  };
  if (isButton2MessageVisible) {
    showButtonMessage(2);
  } else {
    hideButtonMessage(2);
  };
  if (isButton3MessageVisible) {
    showButtonMessage(3);
  } else {
    hideButtonMessage(3);
  };
  if (isButton4MessageVisible) {
    showButtonMessage(4);
  } else {
    hideButtonMessage(4);
  };
  if (isButton5MessageVisible) {
    showButtonMessage(5);
  } else {
    hideButtonMessage(5);
  };
  if (isButton6MessageVisible) {
    showButtonMessage(6);
  } else {
    hideButtonMessage(6);
  };
  if (isButton7MessageVisible) {
    showButtonMessage(7);
  } else {
    hideButtonMessage(7);
  };
  if (isButton8MessageVisible) {
    showButtonMessage(8);
  } else {
    hideButtonMessage(8);
  };
  if (isButton1MessageVisible || isButton2MessageVisible || isButton3MessageVisible || isButton4MessageVisible || isButton5MessageVisible || isButton6MessageVisible || isButton7MessageVisible || isButton8MessageVisible) {
    showButtonMessageContainer();
  } else {
    hideButtonMessageContainer();
  }
}

function isButtonVisible(buttonNumber) {
  const hours = new Date().getHours();
  const mins = new Date().getMinutes();
  const day = dayToString(new Date().getDay());
  let afterStart, beforeEnd, isToday;
  let buttonIsPermanent, buttonShowTime, buttonHideTime, buttonShowDays;
  switch(buttonNumber) {
    case 1:
      buttonIsPermanent = gon.button1Permanent;
      buttonShowTime = new Date(gon.button1ShowTime);
      buttonHideTime = new Date(gon.button1HideTime);
      buttonShowDays = gon.button1ShowDays;
      break;
    case 2:
      buttonIsPermanent = gon.button2Permanent;
      buttonShowTime = new Date(gon.button2ShowTime);
      buttonHideTime = new Date(gon.button2HideTime);
      buttonShowDays = gon.button2ShowDays;
      break;
    case 3:
      buttonIsPermanent = gon.button3Permanent;
      buttonShowTime = new Date(gon.button3ShowTime);
      buttonHideTime = new Date(gon.button3HideTime);
      buttonShowDays = gon.button3ShowDays;
      break;
    case 4:
      buttonIsPermanent = gon.button4Permanent;
      buttonShowTime = new Date(gon.button4ShowTime);
      buttonHideTime = new Date(gon.button4HideTime);
      buttonShowDays = gon.button4ShowDays;
      break;
    case 5:
      buttonIsPermanent = gon.button5Permanent;
      buttonShowTime = new Date(gon.button5ShowTime);
      buttonHideTime = new Date(gon.button5HideTime);
      buttonShowDays = gon.button5ShowDays;
      break;
    case 6:
      buttonIsPermanent = gon.button6Permanent;
      buttonShowTime = new Date(gon.button6ShowTime);
      buttonHideTime = new Date(gon.button6HideTime);
      buttonShowDays = gon.button6ShowDays;
      break;
    case 7:
      buttonIsPermanent = gon.button7Permanent;
      buttonShowTime = new Date(gon.button7ShowTime);
      buttonHideTime = new Date(gon.button7HideTime);
      buttonShowDays = gon.button7ShowDays;
      break;
    case 8:
      buttonIsPermanent = gon.button8Permanent;
      buttonShowTime = new Date(gon.button8ShowTime);
      buttonHideTime = new Date(gon.button8HideTime);
      buttonShowDays = gon.button8ShowDays;
      break;
  }
  if (typeof(buttonIsPermanent) === 'undefined'
    || typeof(buttonShowTime) === 'undefined'
    || typeof(buttonHideTime) === 'undefined'
    || typeof(buttonShowDays) === 'undefined') {
    return false;
  }
  if (buttonIsPermanent) {
    return true;
  }
  afterStart = (hours > buttonShowTime.getHours()) || (hours === buttonShowTime.getHours() && mins >= buttonShowTime.getMinutes());
  beforeEnd = (hours < buttonHideTime.getHours()) || (hours === buttonHideTime.getHours() && mins < buttonHideTime.getMinutes());
  isToday = buttonShowDays.includes(day);
  if (isToday && afterStart && beforeEnd) {
    return true;
  } else {
    return false;
  }
}

// messages have their own start time by end at the button end time
function isButtonMessageVisible(buttonNumber) {
  const hours = new Date().getHours();
  const mins = new Date().getMinutes();
  const day = dayToString(new Date().getDay());
  let afterStart, beforeEnd, isToday;
  let buttonIsPermanent, buttonMessageIsPresent, buttonMessageShowTime, buttonHideTime, buttonShowDays;
  switch(buttonNumber) {
    case 1:
      if (typeof gon.button1MessagePresent === undefined) { return false; }
      buttonIsPermanent = gon.button1Permanent;
      buttonMessageIsPresent = gon.button1MessagePresent;
      buttonMessageShowTime = new Date(gon.button1MessageShowTime);
      buttonHideTime = new Date(gon.button1HideTime);
      buttonShowDays = gon.button1ShowDays;
      break;
    case 2:
      if (typeof gon.button2MessagePresent === undefined) { return false; }
      buttonIsPermanent = gon.button2Permanent;
      buttonMessageIsPresent = gon.button2MessagePresent;
      buttonMessageShowTime = new Date(gon.button2MessageShowTime);
      buttonHideTime = new Date(gon.button2HideTime);
      buttonShowDays = gon.button2ShowDays;
      break;
    case 3:
      if (typeof gon.button3MessagePresent === undefined) { return false; }
      buttonIsPermanent = gon.button3Permanent;
      buttonMessageIsPresent = gon.button3MessagePresent;
      buttonMessageShowTime = new Date(gon.button3MessageShowTime);
      buttonHideTime = new Date(gon.button3HideTime);
      buttonShowDays = gon.button3ShowDays;
      break;
    case 4:
      if (typeof gon.button4MessagePresent === undefined) { return false; }
      buttonIsPermanent = gon.button4Permanent;
      buttonMessageIsPresent = gon.button4MessagePresent;
      buttonMessageShowTime = new Date(gon.button4MessageShowTime);
      buttonHideTime = new Date(gon.button4HideTime);
      buttonShowDays = gon.button4ShowDays;
      break;
    case 5:
      if (typeof gon.button5MessagePresent === undefined) { return false; }
      buttonIsPermanent = gon.button5Permanent;
      buttonMessageIsPresent = gon.button5MessagePresent;
      buttonMessageShowTime = new Date(gon.button5MessageShowTime);
      buttonHideTime = new Date(gon.button5HideTime);
      buttonShowDays = gon.button5ShowDays;
      break;
    case 6:
      if (typeof gon.button6MessagePresent === undefined) { return false; }
      buttonIsPermanent = gon.button6Permanent;
      buttonMessageIsPresent = gon.button6MessagePresent;
      buttonMessageShowTime = new Date(gon.button6MessageShowTime);
      buttonHideTime = new Date(gon.button6HideTime);
      buttonShowDays = gon.button6ShowDays;
      break;
    case 7:
      if (typeof gon.button7MessagePresent === undefined) { return false; }
      buttonIsPermanent = gon.button7Permanent;
      buttonMessageIsPresent = gon.button7MessagePresent;
      buttonMessageShowTime = new Date(gon.button7MessageShowTime);
      buttonHideTime = new Date(gon.button7HideTime);
      buttonShowDays = gon.button7ShowDays;
      break;
    case 8:
      if (typeof gon.button8MessagePresent === undefined) { return false; }
      buttonIsPermanent = gon.button8Permanent;
      buttonMessageIsPresent = gon.button8MessagePresent;
      buttonMessageShowTime = new Date(gon.button8MessageShowTime);
      buttonHideTime = new Date(gon.button8HideTime);
      buttonShowDays = gon.button8ShowDays;
      break;
  }
  if (typeof(buttonIsPermanent) === 'undefined'
    || typeof(buttonMessageIsPresent) === 'undefined'
    || typeof(buttonMessageShowTime) === 'undefined'
    || typeof(buttonHideTime) === 'undefined'
    || typeof(buttonShowDays) === 'undefined') {
    return false;
  }
  if ((buttonIsPermanent == true) || (buttonMessageIsPresent ==  false)) {
    return false;
  }
  afterStart = (hours > buttonMessageShowTime.getHours()) || (hours === buttonMessageShowTime.getHours() && mins >= buttonMessageShowTime.getMinutes());
  beforeEnd = (hours < buttonHideTime.getHours()) || (hours === buttonHideTime.getHours() && mins < buttonHideTime.getMinutes());
  isToday = buttonShowDays.includes(day);
  if (isToday && afterStart && beforeEnd) {
    return true;
  } else {
    return false;
  }
}

function dayToString(dayNumber) {
  let day;
  switch (dayNumber) {
    case 0:
      day = "Sun";
      break;
    case 1:
      day = "Mon";
      break;
    case 2:
      day = "Tue";
      break;
    case 3:
      day = "Wed";
      break;
    case 4:
      day = "Thu";
      break;
    case 5:
      day = "Fri";
      break;
    case 6:
      day = "Sat";
      break;
  }
  return day;
}

function showButton(buttonNumber) {
  const id = `#button-${buttonNumber}-id`;
  if ($(id).is(':hidden')) {
    $(id).show();
  }
}

function hideButton(buttonNumber) {
  const id = `#button-${buttonNumber}-id`;
  if ($(id).is(':visible')) {
    $(id).hide();
  }
}

function showButtonMessage(buttonNumber) {
  // console.log('in showButtonMessage ' + buttonNumber);
  const id = `#button-message-${buttonNumber}-id`;
  if ($(id).is(':hidden')) {
    $(id).show();
  }
}

function hideButtonMessage(buttonNumber) {
  // console.log('in hideButtonMessage ' + buttonNumber);
  const id = `#button-message-${buttonNumber}-id`;
  if ($(id).is(':visible')) {
    $(id).hide();
  }
}

function showButtonMessageContainer() {
  // console.log('in showButtonMessageContainer');
  if ($('#button-message-container').is(':hidden')) {
    $('#button-message-container').show();
  }
}

function hideButtonMessageContainer() {
  // console.log('in hideButtonMessageContainer');
  if ($('#button-message-container').is(':visible')) {
    $('#button-message-container').hide();
  }
}
