export function showNoNewsPage() {
  document.querySelector('#ipad-display-container').style.display = "none";
  document.querySelector('#no-news-container').style.display = "block";
}

export function changePage(oldId, newId) {
  const oldIdRef = `#${oldId}`
  const newIdRef = `#${newId}`
  if($(oldIdRef).length) { // old page exists
    $(oldIdRef).fadeOut(500, function () {
      $(oldIdRef + ' .carousel').carousel('pause');
      $(oldIdRef).css("display", "none");
      $(newIdRef + ' .carousel').carousel({ });
      $(newIdRef + ' .carousel').carousel(0);
      $(newIdRef + ' .carousel').carousel('cycle');
      $(newIdRef).fadeIn(500);
    });
  } else {
    $(newIdRef + ' .carousel').carousel({ });
    $(newIdRef + ' .carousel').carousel(0);
    $(newIdRef + ' .carousel').carousel('cycle');
    $(newIdRef).fadeIn(500);
  }
}

export function logMessage(array, position) {
  console.log(`Showing page [${position}] with name "${array[position]["name"]}" for ${array[position]["time"]}s `);
}
