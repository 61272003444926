function dimScreen() {
  // console.log("dim screen");
  document.querySelector('#ipad-display-container').style.display = "none";
  document.querySelector('#ipad-display-dimmed').style.display = "block";
}

function undimScreen() {
  // console.log("undim screen");
  document.querySelector('#ipad-display-container').style.display = "block";
  document.querySelector('#ipad-display-dimmed').style.display = "none";
}

function nightTime() {
  const hours = new Date().getHours()
  if (hours >= 22 || hours < 7) {
    return true;
  } else {
    return false;
  }
}

export function dimAtNight(dimmed) {
  if (!dimmed && nightTime()) {
    dimScreen();
    return true;
  } else if (dimmed && !nightTime) {
    undimScreen();
    return false;
  }
}
