// disables the tablet form is user is buying a subscription

$(document).on('turbolinks:load', function() {

  let purchase_option_id = document.getElementById("purchase_option_id")

  if (purchase_option_id) {
    if (purchase_option_id.value == 'subscription') {
      document.getElementById("tab_card").classList.add("grey-bg");
      document.getElementById("sub_card").classList.remove("grey-bg");
      document.getElementById("select_lists").classList.add("d-none");
      } else {
      document.getElementById("sub_card").classList.add("grey-bg");
      document.getElementById("tab_card").classList.remove("grey-bg");
      document.getElementById("select_lists").classList.remove("d-none");
      }
  };

  // subscription selected
  $("#sub_card").on("click", function() {
    document.getElementById("tab_card").classList.add("grey-bg");
    document.getElementById("sub_card").classList.remove("grey-bg");
    document.getElementById("select_lists").classList.add("d-none");
    document.getElementById("purchase_option_id").value = 'subscription'
  });

  // tablet selected
  $("#tab_card").on("click", function() {
    document.getElementById("sub_card").classList.add("grey-bg");
    document.getElementById("tab_card").classList.remove("grey-bg");
    document.getElementById("select_lists").classList.remove("d-none");
    document.getElementById("purchase_option_id").value = 'tablet'
  });

});
